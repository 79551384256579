import _ from "lodash";
import moment from "moment";
import * as rootStyles from "../view/styles";
import {
    TimeRange,
    GranularityType,
    query,
    album,
    artist,
    track,
    playlist,
    station,
    trackInfo,
    albumInfo,
    programmingInfo,
    catalog,
    catalogItem,
    streamDetails,
    AlexaStreamingType,
    TableRowProps,
    countryInfo,
    cityInfo,
    recentlyAddedToPlaylistTrack,
    recentlyAddedToPlaylistPayload,
    albumRelease,
    mediaForPitch,
    itemProps,
    genreInfo,
    group,
    tag,
    baseMediaItem,
    PitchStatus,
    trackInfoForPitch,
    baseCatalogItem,
    ExperiencesPage,
    BundleMap,
    ArtistMarqueePlaylistTrack,
    ContentType,
    ArtistMarqueePlaylists,
} from "../models";
import {
    getLocalizedString,
    formatNumber,
    catalogHelper,
    translateCountryCodeToCountryName,
    canTranslateCountryCodeToCountryName,
    translateContentTypeToString,
    PromoCardLogoColor,
} from "../utils";
import { paths, getStartDate } from "./";
import { stringIds, ImageList, bundleIds } from "../assets";
import { reportingOverviewRequestPayload, timeRangePayload } from "../models";
import { imageResizer } from "./imageHelper";
import { getUTCMidnight, getLatestUTCTimeInDay } from "./timestampHelper";
import { webLocale } from "./localeType";
import { CommonTestData } from "../models/commonTestData";

const MAX_NUM_TERRITORIES: number = 4;
const PREFERRED_TERRITORY_ORDER: string[] = [
    "US",
    "UK",
    "DE",
    "JP",
    "FR",
    "IT",
    "ES",
];

// Not adding oobe page here because header logo depends on this too
const hideNavBarPaths: string[] = [
    paths.acceptInvite,
    paths.optOut,
    paths.modLanding,
];
const hideHeaderPaths: string[] = [paths.modLanding];

export const generateHeader = (headers: string[]) => {
    const row = {
        primary: headers[0],
        stat1: headers[1],
        stat2: headers[2],
    };
    return row;
};

export const getQueryTimeZone = (
    timeRange: TimeRange,
    userTimeZone?: string
) => {
    return timeRange === TimeRange.Today && userTimeZone ? userTimeZone : "UTC";
};

export const getGranularity = (timeRange: TimeRange) => {
    switch (timeRange) {
        case TimeRange.SevenDays:
        case TimeRange.TwentyEightDays:
        case TimeRange.Custom:
            return GranularityType.Day;
        case TimeRange.Today:
            return GranularityType.Hour;
        default:
            return GranularityType.Week;
    }
};

export const generateQuery = (
    timeRangePayload: timeRangePayload,
    timeZone: string,
    resultSize?: number
) => {
    return {
        ...generateQueryWithoutGranularity(
            timeRangePayload,
            timeZone,
            resultSize
        ),
        granularity: getGranularity(timeRangePayload.timeRange),
    };
};

export const generateQueryWithoutGranularity = (
    timeRangePayload: timeRangePayload,
    timeZone: string,
    resultSize?: number
): query => {
    const startDate = timeRangePayload?.startDate
        ? getUTCMidnight(timeRangePayload.startDate)
        : getStartDate(timeRangePayload.timeRange);
    const endDate = timeRangePayload?.endDate
        ? getLatestUTCTimeInDay(timeRangePayload.endDate)
        : new Date();
    const queryTimeZone = getQueryTimeZone(
        timeRangePayload.timeRange,
        timeZone
    );

    return {
        endTime: endDate.toISOString(),
        resultSize: resultSize ? resultSize : 50,
        startTime: startDate?.toISOString(),
        timeZone: queryTimeZone,
    };
};

export const getCatalogItem = (
    asin: string,
    catalog: catalog
): catalogItem | undefined => {
    return catalog.get(asin);
};

export function checkIfNotEmpty<TValue>(
    value: TValue | null | undefined
): value is TValue {
    return value !== null && value !== undefined;
}

export const getSeeAllCountriesRequestId = (
    entity: string,
    asin: string,
    timeRangePayload: timeRangePayload
) => {
    if (timeRangePayload.timeRange === TimeRange.Custom) {
        return `seeAll_${entity}_${asin}_topCountries_${timeRangePayload.startDate?.toString()}_${timeRangePayload.endDate?.toString()}`;
    }
    return `seeAll_${entity}_${asin}_topCountries_${timeRangePayload.timeRange.toString()}`;
};

export const generateAlbumRow = (
    stat: number,
    onSelect: () => void,
    album: album,
    artist: artist | undefined,
    id: string,
    isLoading?: boolean
) => {
    const row: TableRowProps = {
        primary: album && album.title,
        image:
            album && album.images && album.images.artSmall
                ? album.images.artSmall
                : artist &&
                  artist.imageUrl &&
                  imageResizer(
                      artist.imageUrl,
                      rootStyles.ImageSize.Medium,
                      artist && artist.imageUrl
                  ),
        fallbackImage: ImageList.placeholder_album,
        stat1: formatNumber(stat),
        onSelect: onSelect,
        isLoading: isLoading,
        id,
        imageDescription: album && album.title,
    };
    return row;
};

export const generateSongRow = (
    stat: number,
    onSelect: () => void,
    catalog: catalog,
    track: track,
    index: string | undefined,
    id: string,
    isLoading?: boolean,
    showIndex?: boolean,
    hideImage?: boolean,
    showBottomBorder?: boolean,
    displayNewBadge?: boolean,
    dateAdded?: string
) => {
    const row: TableRowProps = {
        primary: track && track.title,
        image: hideImage
            ? undefined
            : track && track.images && track.images.artSmall,
        secondary: track && concatenateArtistNames(track.artistAsins, catalog),
        fallbackImage: hideImage ? undefined : ImageList.placeholder_track,
        stat1: formatNumber(stat),
        onSelect: onSelect,
        index: showIndex ? index : undefined,
        isLoading: isLoading,
        id,
        imageDescription: track && track.title,
        showBottomBorder: showBottomBorder,
        displayNewBadge: displayNewBadge,
        date: dateAdded ? formatDate(new Date(dateAdded)) : undefined,
    };
    return row;
};

export const generateCountryRow = (
    stat: number,
    country: countryInfo,
    id: string
) => {
    const row: TableRowProps = {
        primary:
            country && translateCountryCodeToCountryName(country.countryCode),
        stat1: formatNumber(stat),
        id,
    };
    return row;
};

export const generateUnavailableDataRow = (
    id: string,
    stat: string,
    bundleMap: BundleMap,
    fallback?: any,
    index?: string,
    showIndex?: boolean,
    hideImage?: boolean,
    showBottomBorder?: boolean
) => {
    const row: TableRowProps = {
        primary: getLocalizedString(bundleMap, {
            bundleId: bundleIds.GENERIC_STRINGS,
            stringId: stringIds.Generic.missingCatalogDataTitle,
        }),
        stat1: stat,
        fallbackImage: hideImage ? undefined : fallback,
        index: showIndex ? index : undefined,
        showBottomBorder: showBottomBorder,
        id,
    };
    return row;
};

export const generateNewAddsToPlaylistsRow = (
    playlist: playlist,
    onSelect: () => void,
    dateAdded: string,
    id: string,
    isLoading?: boolean
): TableRowProps => {
    return {
        primary: playlist?.title,
        secondary:
            playlist &&
            generateTerritoryList(playlist.territories, MAX_NUM_TERRITORIES),
        image: playlist?.images?.artSmall,
        fallbackImage: ImageList.placeholder_playlist,
        stat1: dateAdded,
        onSelect,
        isLoading,
        id,
    };
};

export const generateNewReleaseRow = (
    catalogItem: baseCatalogItem,
    onSelect: any,
    stat1: any,
    artistNames: string | undefined,
    releaseDate: string | undefined,
    id: string,
    isLoading?: boolean,
    index?: string,
    showIndex?: boolean,
    hideImage?: boolean,
    showBottomBorder?: boolean
): TableRowProps => {
    return {
        primary: catalogItem?.title,
        secondary: artistNames,
        tertiary: releaseDate,
        onSelect: onSelect,
        image: hideImage
            ? undefined
            : catalogItem && catalogItem.images?.artSmall,
        fallbackImage: hideImage ? undefined : ImageList.placeholder_album,
        stat1: stat1,
        isLoading: isLoading,
        id,
        imageDescription: catalogItem && catalogItem.title,
        index: showIndex ? index : undefined,
        showBottomBorder: showBottomBorder,
    };
};

// If there's more than one artist loaded, returns a comma-delimited list of artists.
//  Otherwise, return undefined.
export const concatenateArtistNames = (
    asins: string[],
    catalog: catalog,
    showIfOnlyOneArtist?: boolean
) => {
    let artists = "";
    let first = true;

    for (const asin of asins) {
        const artist = getCatalogItem(asin, catalog) as artist;
        if (artist && artist.title) {
            if (!first) {
                artists += ", ";
            }

            artists += artist.title;
            first = false;
        }
    }

    const onlyOneResult = showIfOnlyOneArtist ? artists : undefined;

    return artists.length === 0 || artists.indexOf(",") === -1
        ? onlyOneResult
        : artists;
};

export const generateLoadingDataRow = (
    id: string,
    stat: number,
    primary?: string,
    secondary?: string,
    fallback?: any,
    index?: string,
    showIndex?: boolean,
    hideImage?: boolean,
    showBottomBorder?: boolean
) => {
    const row: TableRowProps = {
        fallbackImage: hideImage ? undefined : fallback,
        isLoading: true,
        index: showIndex ? index : undefined,
        stat1: formatNumber(stat),
        primary: primary,
        secondary: secondary,
        showBottomBorder: showBottomBorder,
        id,
    };
    return row;
};

export const generatePlaylistRow = (
    stat: number,
    onSelect: () => void,
    playlist: playlist,
    id: string,
    isLoading?: boolean,
    displayNewBadge?: boolean
) => {
    const row: TableRowProps = {
        primary: playlist && playlist.title,
        secondary:
            playlist &&
            generateTerritoryList(playlist.territories, MAX_NUM_TERRITORIES),
        image: playlist && playlist.images && playlist.images.artSmall,
        fallbackImage: ImageList.placeholder_playlist,
        stat1: formatNumber(stat),
        onSelect: onSelect,
        isLoading: isLoading,
        id,
        displayNewBadge: displayNewBadge,
    };
    return row;
};

export const generateTerritoryList = (
    territories: string[],
    maxTerritories?: number
): string | undefined => {
    if (!territories || territories.length === 0) {
        return undefined;
    }

    const orderedTerritories: string[] = reorderTerritories(territories);

    const listLength: number = maxTerritories
        ? maxTerritories
        : orderedTerritories.length;

    let list: string = "";

    let first: boolean = true;
    for (
        let i: number = 0;
        i < listLength && i < orderedTerritories.length;
        ++i
    ) {
        if (!first) {
            list += ", ";
        }

        list += orderedTerritories[i];
        first = false;
    }

    if (orderedTerritories.length > listLength) {
        list += "...";
    }

    return list;
};

const reorderTerritories = (territories: string[]): string[] => {
    const newTerritories: string[] = [];

    PREFERRED_TERRITORY_ORDER.forEach((t) => {
        if (territories.includes(t)) {
            newTerritories.push(t);
        }
    });

    territories.forEach((t) => {
        if (!newTerritories.includes(t)) {
            newTerritories.push(t);
        }
    });

    return newTerritories;
};

export const generateStationRow = (
    stat: number,
    onSelect: () => void,
    station: station,
    id: string,
    isLoading?: boolean
) => {
    const row: TableRowProps = {
        primary: station && station.title,
        image: station && station?.images?.artSmall,
        fallbackImage: ImageList.placeholder_station,
        stat1: formatNumber(stat),
        onSelect: onSelect,
        isLoading: isLoading,
        id,
    };
    return row;
};

export const generateAlbumRows = (
    topAlbums: albumInfo[],
    catalog: catalog,
    hydratingAsins: string[],
    _failedAsins: string[],
    callback: any,
    bundleMap: BundleMap,
    selectedArtistAsin: string | undefined,
    id: string
): TableRowProps[] => {
    const returnList = topAlbums.map((d: albumInfo, index: number) => {
        const album = getCatalogItem(d.asin, catalog) as album;

        const rowID = `${id}_${index}`;
        if (catalogHelper.isValidAlbum(album)) {
            const artist = selectedArtistAsin
                ? (getCatalogItem(selectedArtistAsin, catalog) as artist)
                : undefined;
            return generateAlbumRow(
                d.streams,
                () => callback(`${paths.albumPerformance}/${d.asin}`),
                album,
                artist,
                rowID,
                _.includes(hydratingAsins, d.asin)
            );
        }

        if (_.includes(hydratingAsins, d.asin)) {
            return generateLoadingDataRow(
                rowID,
                0,
                "",
                ImageList.placeholder_album,
                undefined,
                undefined,
                undefined,
                undefined,
                false
            );
        }

        return generateUnavailableDataRow(
            rowID,
            formatNumber(d.streams),
            bundleMap,
            ImageList.placeholder_album
        );
    });

    return _.filter(returnList, checkIfNotEmpty);
};

export const generateSongRows = (
    topTracks: trackInfo[],
    catalog: catalog,
    hydratingAsins: string[],
    _failedAsins: string[],
    callback: any,
    bundleMap: BundleMap,
    id: string,
    showIndex?: boolean,
    hideImage?: boolean,
    showBottomBorder?: boolean,
    asinsWithNewBadgeToDateMap?: Map<string, string>
) => {
    const returnList = topTracks.map((d: trackInfo, index: number) => {
        const track = getCatalogItem(d.asin, catalog) as track;

        const rowID = `${id}_${index}`;
        if (catalogHelper.isValidTrack(track)) {
            return generateSongRow(
                d.streams,
                () => callback(`${paths.songPerformance}/${d.asin}`),
                catalog,
                track,
                formatNumber(index),
                rowID,
                _.includes(hydratingAsins, d.asin),
                showIndex,
                hideImage,
                showBottomBorder,
                asinsWithNewBadgeToDateMap?.has(d.asin),
                asinsWithNewBadgeToDateMap?.get(d.asin)
            );
        }

        if (_.includes(hydratingAsins, d.asin)) {
            return generateLoadingDataRow(
                rowID,
                0,
                "",
                "",
                ImageList.placeholder_track,
                formatNumber(index),
                showIndex,
                true,
                true
            );
        }

        return generateUnavailableDataRow(
            rowID,
            formatNumber(d.streams),
            bundleMap,
            ImageList.placeholder_track,
            formatNumber(index),
            showIndex,
            true,
            true
        );
    });

    return _.filter(returnList, checkIfNotEmpty);
};

export const formatDate = (date: Date) => {
    return moment(date).utc().locale(webLocale[0]).format("MMM DD YYYY");
};

/**
 * Used for tables where we display all new playlists, since entries are stored as track-playlist pairs
 */
export const getUniqueNewPlaylists = (
    newPlaylists: recentlyAddedToPlaylistTrack[]
) => {
    const dedupedPlaylists: recentlyAddedToPlaylistTrack[] = [];
    newPlaylists.forEach((track) => {
        const existingEntry = dedupedPlaylists.find(
            (v) => v.playlistSeriesAsin === track.playlistSeriesAsin
        );
        if (!existingEntry) {
            dedupedPlaylists.push({
                playlistSeriesAsin: track.playlistSeriesAsin,
                dateAdded: track.dateAdded,
            });
        }
    });
    return dedupedPlaylists;
};

export type playlistRow = {
    playlistImage: string;
    trackName: string;
    trackAsin: string;
    playlistTitle: string;
    playlistImageName: string;
    playlistLogoColor: PromoCardLogoColor;
    landscapeReversed: boolean;
    playlistAsin: string;
};

export const getArtistMarqueePlaylists = (
    marqueePlaylists: ArtistMarqueePlaylists,
    catalog: catalog
) => {
    const playlistRows: playlistRow[] = [];
    if (marqueePlaylists && marqueePlaylists.tracks) {
        marqueePlaylists.tracks.forEach((track) => {
            if (track.trackAsin) {
                const playlistInfo = catalog.get(
                    track.playlistAsin
                ) as playlist;
                const trackInfo = catalog.get(track.trackAsin) as track;
                if (
                    playlistInfo &&
                    playlistInfo.images &&
                    playlistInfo.images.artExtraLarge &&
                    playlistInfo.title &&
                    trackInfo &&
                    trackInfo.globalAsin &&
                    trackInfo.title &&
                    track.playlistName
                )
                    playlistRows.push({
                        playlistImage: playlistInfo.images.artExtraLarge,
                        trackName: trackInfo.title,
                        trackAsin: trackInfo.globalAsin,
                        playlistTitle: playlistInfo.title,
                        playlistImageName: track.playlistName,
                        playlistLogoColor: track.shades,
                        landscapeReversed: track.landscapeReversed,
                        playlistAsin: track.playlistAsin,
                    });
            }
        });
    }

    return playlistRows;
};

export const getNewTrackAsins = (
    newAddsToPlaylists: recentlyAddedToPlaylistTrack[],
    playlistSeriesAsin?: string
): Map<string, string> => {
    return new Map<string, string>(
        newAddsToPlaylists
            .filter(
                (data) =>
                    playlistSeriesAsin &&
                    data.playlistSeriesAsin === playlistSeriesAsin
            )
            .filter((data) => data.trackTitlesetAsin !== undefined)
            // Will never fall back because of the above check
            .map((data) => [data.trackTitlesetAsin || "", data.dateAdded])
    );
};

export const getNewPlaylistAsins = (
    newAddsToPlaylists: recentlyAddedToPlaylistTrack[],
    trackTitlesetAsin?: string
): Set<string> => {
    return new Set<string>(
        newAddsToPlaylists
            .filter(
                (data) =>
                    trackTitlesetAsin &&
                    data.trackTitlesetAsin === trackTitlesetAsin
            )
            .filter((data) => data.playlistSeriesAsin !== undefined)
            // Will never fall back because of the above check
            .map((data) => data.playlistSeriesAsin || "")
    );
};

export const generateSongRowsForPitch = (
    tracksInfo: trackInfoForPitch[],
    catalog: catalog,
    teamId: string,
    hydrationAsins: string[],
    callback: any,
    rightElementBuilder: (
        status: string,
        bundleMap: BundleMap,
        pitchDate: string | undefined,
        isPrivileged: boolean | undefined,
        id: string
    ) => any,
    isPrivileged: boolean | undefined,
    id: string,
    bundleMap: BundleMap,
    showIndex?: boolean,
    hideImage?: boolean,
    showBottomBorder?: boolean
): TableRowProps[] => {
    const returnList = _.map(
        tracksInfo,
        (trackInfo: trackInfoForPitch, index: number) => {
            const track = getCatalogItem(
                trackInfo.titlesetAsin,
                catalog
            ) as track;
            const formattedPitchdate = trackInfo.pitchedDate
                ? formatDate(new Date(trackInfo.pitchedDate))
                : undefined;

            const onSelect =
                trackInfo.status === PitchStatus.Pitchable ||
                isPrivileged ||
                CommonTestData.testTrackTitlesetAsin.includes(
                    trackInfo.titlesetAsin
                )
                    ? () => callback(trackInfo.titlesetAsin)
                    : undefined;

            const rowID = `${id}_${trackInfo.titlesetAsin}`;

            if (catalogHelper.isValidTrack(track)) {
                const artistNames = concatenateArtistNames(
                    track.artistAsins,
                    catalog
                );
                return generateNewReleaseRow(
                    track,
                    onSelect,
                    rightElementBuilder(
                        trackInfo.status,
                        bundleMap,
                        formattedPitchdate,
                        isPrivileged,
                        `${id}_${trackInfo.titlesetAsin}`
                    ),
                    artistNames,
                    undefined,
                    rowID,
                    _.includes(hydrationAsins, trackInfo.titlesetAsin),
                    track.trackNumber
                        ? formatNumber(track.trackNumber)
                        : undefined,
                    showIndex,
                    hideImage,
                    showBottomBorder
                );
            }
            if (_.includes(hydrationAsins, trackInfo.titlesetAsin)) {
                return generateLoadingDataRow(
                    rowID,
                    0,
                    "",
                    "",
                    ImageList.placeholder_track,
                    formatNumber(index),
                    showIndex,
                    true,
                    true
                );
            }

            return generateUnavailableDataRow(
                rowID,
                "",
                bundleMap,
                ImageList.placeholder_track,
                formatNumber(index),
                showIndex,
                true,
                true
            );
        }
    );

    return _.filter(returnList, checkIfNotEmpty);
};

export const generateAlbumReleaseRows = (
    albumReleases: albumRelease[],
    experiencesForEntities: Map<string, ExperiencesPage> | undefined,
    catalog: catalog,
    hydrationAsins: string[],
    pitchCallback: (albumRelease: albumRelease) => void,
    announcementCallback: (albumRelease: albumRelease) => void,
    rightElementBuilder: (
        status: string,
        pitchButtonCallback: () => void,
        anouncementButtonCallback: (() => void) | undefined,
        hasPitchPermissions: boolean,
        hasAnnouncementPermissions: boolean,
        bundleMap: BundleMap,
        pitchDate: string | undefined,
        id: string
    ) => JSX.Element,
    hasPitchPermissions: boolean,
    hasAnnouncementPermissions: boolean,
    bundleMap: BundleMap,
    id: string
): TableRowProps[] => {
    const returnList = _.map(albumReleases, (albumRelease: albumRelease) => {
        const album = getCatalogItem(
            albumRelease.titlesetAsin,
            catalog
        ) as album;
        const formattedReleaseDate = formatDate(
            new Date(albumRelease.releaseDate)
        );
        const releaseDateString = getLocalizedString(
            bundleMap,
            {
                bundleId: bundleIds.PITCH_STRINGS,
                stringId: stringIds.Pitch.pitchAlbumReleaseDate,
            },
            { "0": formattedReleaseDate }
        );

        const formattedPitchDate = albumRelease.pitchedDate
            ? formatDate(new Date(albumRelease.pitchedDate))
            : undefined;

        const experience = experiencesForEntities?.get(album.globalAsin || "");

        const rowID = `${id}_${albumRelease.titlesetAsin}`;

        if (catalogHelper.isValidAlbum(album)) {
            const artistNames = concatenateArtistNames(
                album.artistAsins,
                catalog
            );
            return generateNewReleaseRow(
                album,
                undefined,
                rightElementBuilder(
                    albumRelease.status,
                    () => pitchCallback(albumRelease),
                    !!experience && experience.experiences.length !== 0
                        ? undefined
                        : () => announcementCallback(albumRelease),
                    hasPitchPermissions,
                    hasAnnouncementPermissions,
                    bundleMap,
                    formattedPitchDate,
                    `${rowID}_RightElement`
                ),
                artistNames,
                releaseDateString,
                rowID,
                _.includes(hydrationAsins, albumRelease.titlesetAsin)
            );
        }
        if (_.includes(hydrationAsins, albumRelease.titlesetAsin)) {
            return generateLoadingDataRow(
                rowID,
                0,
                "",
                ImageList.placeholder_album
            );
        }

        return generateUnavailableDataRow(
            rowID,
            "",
            bundleMap,
            ImageList.placeholder_album
        );
    });
    return _.filter(returnList, checkIfNotEmpty);
};

export const generateTrackItems = (
    topTracks: trackInfo[] | undefined,
    catalog: catalog
): baseMediaItem[] => {
    if (!topTracks || topTracks.length === 0 || !catalog) {
        return [];
    }

    const tracks: baseMediaItem[] = [];
    topTracks.forEach((track) => {
        const catalogTrack = getCatalogItem(track.asin, catalog) as track;

        if (
            catalogHelper.isValidTrack(catalogTrack) &&
            catalogTrack.title &&
            catalogTrack.images &&
            catalogTrack.localOriginalReleaseDate
        ) {
            const trackAlbum: baseMediaItem = {
                asin: track.asin,
                globalAsin: track.globalAsin,
                type: ContentType.Track,
                title: catalogTrack.title,
                images: catalogTrack.images,
                localOriginalReleaseDate: catalogTrack.localOriginalReleaseDate,
            };

            tracks.push(trackAlbum);
        }
    });

    return tracks;
};

export const generateAlbumItems = (
    topAlbums: albumInfo[] | undefined,
    catalog: catalog
): baseMediaItem[] => {
    if (!topAlbums || topAlbums.length === 0 || !catalog) {
        return [];
    }

    const albums: baseMediaItem[] = [];
    topAlbums.forEach((album) => {
        const catalogAlbum = getCatalogItem(album.asin, catalog) as album;

        if (
            catalogHelper.isValidAlbum(catalogAlbum) &&
            catalogAlbum.title &&
            catalogAlbum.images &&
            catalogAlbum.localOriginalReleaseDate
        ) {
            const trackAlbum: baseMediaItem = {
                asin: album.asin,
                globalAsin: album.globalAsin,
                type: ContentType.Album,
                title: catalogAlbum.title,
                images: catalogAlbum.images,
                localOriginalReleaseDate: catalogAlbum.localOriginalReleaseDate,
            };

            albums.push(trackAlbum);
        }
    });

    return albums;
};

export const generateNewAddsToPlaylistsRows = (
    newPlaylists: recentlyAddedToPlaylistTrack[],
    catalog: catalog,
    hydratingAsins: string[],
    callback: any,
    id: string,
    bundleMap: BundleMap
): TableRowProps[] => {
    const returnList = _.map(
        newPlaylists,
        (d: recentlyAddedToPlaylistTrack) => {
            const playlist = getCatalogItem(
                d.playlistSeriesAsin,
                catalog
            ) as playlist;
            const formattedDate = formatDate(new Date(d.dateAdded));

            const rowID = `${id}_${d.playlistSeriesAsin}`;
            if (catalogHelper.isValidPlaylist(playlist)) {
                return generateNewAddsToPlaylistsRow(
                    playlist,
                    () =>
                        callback(
                            `${paths.playlistPerformance}/${d.playlistSeriesAsin}`
                        ),
                    formattedDate,
                    rowID,
                    _.includes(hydratingAsins, d.playlistSeriesAsin)
                );
            }

            if (_.includes(hydratingAsins, d.playlistSeriesAsin)) {
                return generateLoadingDataRow(
                    rowID,
                    0,
                    "",
                    ImageList.placeholder_playlist
                );
            }

            return generateUnavailableDataRow(
                rowID,
                formattedDate,
                bundleMap,
                ImageList.placeholder_playlist
            );
        }
    );

    return _.filter(returnList, checkIfNotEmpty);
};

export const generatePlaylistRows = (
    playlists: programmingInfo[],
    catalog: catalog,
    hydratingAsins: string[],
    _failedAsins: string[],
    callback: any,
    id: string,
    bundleMap: BundleMap,
    asinsWithNewBadge?: Set<string>
): TableRowProps[] => {
    const returnList = playlists.map((d: programmingInfo) => {
        const playlist = getCatalogItem(d.id, catalog) as playlist;

        const rowID = `${id}_${d.id}`;

        if (catalogHelper.isValidPlaylist(playlist)) {
            return generatePlaylistRow(
                d.streams,
                () => callback(`${paths.playlistPerformance}/${d.id}`),
                playlist,
                rowID,
                _.includes(hydratingAsins, d.id),
                asinsWithNewBadge?.has(d.id)
            );
        }

        if (_.includes(hydratingAsins, d.id)) {
            return generateLoadingDataRow(
                rowID,
                0,
                "",
                ImageList.placeholder_playlist
            );
        }

        return generateUnavailableDataRow(
            rowID,
            formatNumber(d.streams),
            bundleMap,
            ImageList.placeholder_playlist
        );
    });

    return _.filter(returnList, checkIfNotEmpty);
};

export const generateStationRows = (
    featuredStations: programmingInfo[],
    catalog: catalog,
    hydratingAsins: string[],
    _failedAsins: string[],
    callback: any,
    id: string,
    bundleMap: BundleMap
): TableRowProps[] => {
    const returnList = featuredStations.map((d: programmingInfo) => {
        const station = getCatalogItem(d.id, catalog) as station;

        const rowID = `${id}_${d.id}`;

        if (catalogHelper.isValidStation(station)) {
            return generateStationRow(
                d.streams,
                () => callback(`${paths.stationPerformance}/${station.asin}`),
                station,
                rowID,
                _.includes(hydratingAsins, d.id)
            );
        }

        if (_.includes(hydratingAsins, d.id)) {
            return generateLoadingDataRow(
                rowID,
                0,
                "",
                ImageList.placeholder_station
            );
        }

        return generateUnavailableDataRow(
            rowID,
            formatNumber(d.streams),
            bundleMap,
            ImageList.placeholder_station
        );
    });

    return _.filter(returnList, checkIfNotEmpty);
};

export const generateCountryRows = (
    topCountries: countryInfo[],
    id: string
): TableRowProps[] => {
    // Filtering out all country codes that can't be translated.
    const filteredTopCountries =
        (topCountries &&
            topCountries.filter((country) =>
                canTranslateCountryCodeToCountryName(country.countryCode)
            )) ||
        [];
    const returnList = filteredTopCountries.map((d: countryInfo) => {
        return generateCountryRow(d.listeners, d, `${id}_${d.countryCode}`);
    });

    return _.filter(returnList || [], checkIfNotEmpty);
};

export const generateAlexaVoiceRequestDetailRows = (
    streamDetails: streamDetails[],
    bundleMap: BundleMap,
    id: string
): TableRowProps[] => {
    if (!streamDetails) {
        return [];
    }

    const artistDetails = streamDetails.find(
        (element) => element.action === AlexaStreamingType.Artist
    );
    const albumDetails = streamDetails.find(
        (element) => element.action === AlexaStreamingType.Album
    );
    const trackDetails = streamDetails.find(
        (element) => element.action === AlexaStreamingType.Track
    );
    const lyricDetails = streamDetails.find(
        (element) => element.action === AlexaStreamingType.Lyrics
    );

    return [
        {
            primary: getLocalizedString(bundleMap, {
                bundleId: bundleIds.ALEXA_STRINGS,
                stringId: stringIds.Alexa.artistRequests,
            }),
            stat1: formatNumber(artistDetails ? artistDetails.count : 0),
            id: `${id}_ArtistRequests`,
        },
        {
            primary: getLocalizedString(bundleMap, {
                bundleId: bundleIds.ALEXA_STRINGS,
                stringId: stringIds.Alexa.albumRequests,
            }),
            stat1: formatNumber(albumDetails ? albumDetails.count : 0),
            id: `${id}_AlbumRequests`,
        },
        {
            primary: getLocalizedString(bundleMap, {
                bundleId: bundleIds.ALEXA_STRINGS,
                stringId: stringIds.Alexa.songRequests,
            }),
            stat1: formatNumber(trackDetails ? trackDetails.count : 0),
            id: `${id}_SongRequests`,
        },
        {
            primary: getLocalizedString(bundleMap, {
                bundleId: bundleIds.ALEXA_STRINGS,
                stringId: stringIds.Alexa.lyricsRequests,
            }),
            stat1: formatNumber(lyricDetails ? lyricDetails.count : 0),
            id: `${id}_LyricRequests`,
        },
    ].filter(checkIfNotEmpty);
};

export const getReportsHelper = (
    getReports: (payload: reportingOverviewRequestPayload) => void,
    getRecentlyAddedToPlaylist: (
        payload: recentlyAddedToPlaylistPayload
    ) => void,
    selectedRange: TimeRange,
    path: string,
    selectedArtist?: string,
    teamId?: string,
    startDate?: Date,
    endDate?: Date,
    userLocale?: string
) => {
    if (!selectedArtist || !teamId) {
        return;
    }
    // Dispatch request for overview
    const payload: reportingOverviewRequestPayload = {
        timeRange: selectedRange,
        teamId: teamId,
        artistAsin: selectedArtist,
        requestPath: path,
        startDate: startDate,
        endDate: endDate,
        locale: userLocale,
    };

    getReports(payload);
    if (selectedArtist && teamId) {
        getRecentlyAddedToPlaylist({
            artistAsin: selectedArtist,
            teamId: teamId,
            requestPath: path,
            locale: userLocale,
        });
    }
};

export const isHideNavBarPath = (path: string | undefined) => {
    if (!path) {
        return false;
    }

    for (let i = 0; i < hideNavBarPaths.length; i++) {
        if (_.includes(path, hideNavBarPaths[i])) {
            return true;
        }
    }
    return false;
};

export const isHideHeaderPath = (path: string | undefined) => {
    if (!path) {
        return false;
    }

    for (let i = 0; i < hideHeaderPaths.length; i++) {
        if (_.includes(path, hideHeaderPaths[i])) {
            return true;
        }
    }
    return false;
};

export const getSelectedContentTypeYearText = (
    type: string | undefined,
    year: string | undefined,
    bundleMap: BundleMap
) => {
    const itemType = type
        ? translateContentTypeToString(type)
            ? getLocalizedString(bundleMap, {
                  bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                  stringId: translateContentTypeToString(type) as string,
              })
            : undefined
        : undefined;
    const itemYear = year && year !== "yyyy" ? year : undefined;

    if (itemType && itemYear) {
        return `${itemType} - ${itemYear}`;
    } else if (itemType) {
        return itemType;
    } else if (itemYear) {
        return itemYear;
    } else {
        return undefined;
    }
};

export const getSelectedContentTypeReleaseDateText = (
    localOriginalReleaseDate: string | undefined
) => {
    if (localOriginalReleaseDate) {
        return `Released ${localOriginalReleaseDate}`;
    } else {
        return undefined;
    }
};

export const getFilteredMediaItem = (
    initialItems: baseMediaItem[],
    itemsToFilter: baseMediaItem[]
) => {
    return initialItems.filter(
        (initialItem) =>
            !itemsToFilter.find(
                (itemToFilter) => itemToFilter.asin === initialItem.asin
            )
    );
};

export const filterStringArrayBySubstring = (
    subString: string,
    stringArray: string[]
) => {
    return stringArray.filter((currentString) =>
        currentString.includes(subString)
    );
};

export const genresInfoToGroups = (genresInfo: genreInfo[]): group[] => {
    return genresInfo.map((genre) => {
        return {
            properties: {
                name: genre.genre.name,
                code: genre.genre.code,
                selected: false,
            } as itemProps,
            items: genre.subGenres.map((subGenre) => {
                return {
                    name: subGenre.name,
                    code: subGenre.code,
                    selected: false,
                } as itemProps;
            }) as itemProps[],
        };
    });
};

export const getUpdatedGroupsWithItemProperties = (
    itemsProps: itemProps[],
    groups: group[]
): group[] => {
    const newGenresInfo: group[] = _.cloneDeep(groups);
    console.log(groups);

    // Filling up itemMap
    const itemMap: Map<string, itemProps> = new Map([]);
    itemsProps.forEach((itemProps) => {
        if (itemProps.code) {
            itemMap.set(itemProps.code, itemProps);
        }
    });

    // updated selected properties
    newGenresInfo.forEach((group: group, groupIndex: number) => {
        group.items.forEach((groupItemProps: itemProps, index: number) => {
            if (groupItemProps.code && itemMap.has(groupItemProps.code)) {
                const itemProps = itemMap.get(groupItemProps.code);
                newGenresInfo[groupIndex].items[index].selected =
                    itemProps?.selected ? itemProps.selected : false;
            } else {
                newGenresInfo[groupIndex].items[index].selected = false;
            }
        });
    });

    return newGenresInfo;
};

export const groupToItemPropsList = (groups: group[]): itemProps[] => {
    const resultItems: itemProps[] = [];
    groups.forEach((group) => {
        group.items.forEach((item) => {
            resultItems.push(item);
        });
    });
    return resultItems;
};

export const selectedInGroupsToItemPropsList = (
    groups: group[]
): itemProps[] => {
    const resultItems: itemProps[] = [];
    groups.forEach((group) => {
        group.items.forEach((item) => {
            if (item.selected) {
                resultItems.push(item);
            }
        });
    });
    return resultItems;
};

export const tagListToItemProps = (tags: tag[]): itemProps[] => {
    return tags.map((tag: tag) => {
        return {
            code: tag.code,
            name: tag.name,
            selected: false,
            hide: false,
        };
    });
};
export const buildDropdownResultsGroupsMatchingText = (
    textInput: string,
    currentGroups: group[]
): group[] => {
    const textInputLowerCase = textInput.toLowerCase();
    const newStateGroups: group[] = _.cloneDeep(currentGroups);
    newStateGroups.forEach((group) => {
        const groupNameLowerCase = group.properties.name.toLowerCase();
        if (groupNameLowerCase.includes(textInputLowerCase)) {
            group.properties.hide = false;
            group.items.forEach((item) => {
                item.hide = false;
            });
        } else {
            group.properties.hide = true;
            group.items.forEach((item) => {
                const itemName = item.name.toLowerCase();
                if (itemName.includes(textInputLowerCase)) {
                    item.hide = false;
                    group.properties.hide = false;
                } else {
                    item.hide = true;
                }
            });
        }
    });

    return newStateGroups;
};

export const tagListToGroupList = (tags: tag[]): group[] => {
    return [
        {
            properties: { code: "", name: "", selected: false },
            items: tags.map((tag) => {
                return {
                    code: tag.code,
                    name: tag.name,
                    selected: false,
                };
            }),
        },
    ];
};

export const selectedGroupItemsToTags = (groups: group[]): tag[] => {
    const items: itemProps[] = selectedInGroupsToItemPropsList(groups);

    return items.map((item) => {
        return {
            code: item.code,
            name: item.name,
        } as tag;
    });
};

export const selectedItemsPropsToTags = (itemsProps: itemProps[]): tag[] => {
    const selectedItemsProps = itemsProps.filter((item) => item.selected);

    return selectedItemsProps.map((item) => {
        return {
            code: item.code,
            name: item.name,
        } as tag;
    });
};

export const tagListToCodeList = (tags: tag[]): string[] => {
    return tags.map((tag) => tag.code);
};

export const mediaForPitchListHasPitchableItem = (
    itemList: mediaForPitch[]
) => {
    return itemList.some((item) => item.status === PitchStatus.Pitchable);
};

export const sortTagsByName = (tags: tag[]): tag[] => {
    const sortedTags: tag[] = tags.sort((tag1, tag2) => {
        return tag1.name > tag2.name ? 1 : -1;
    });

    return sortedTags;
};
